import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

import BannerHeader from '../components/BannerHeader';
import Layout from '../components/Layout';
import ReviewBar from '../components/ReviewBar';
import SEO from '../components/SEO';
import ContactCallToAction from '../components/ContactCallToAction';
import LearnHero from '../components/LearnHero';
import TestimonialCarousel from '../components/TestimonialCarousel';

export const GalleryPageTemplate = ({ header, galleryImages }) => {
  const [photoIndex, setPhotoIndex] = React.useState(0);
  const [isOpen, setIsOpen] = React.useState(false);

  const fullSize = galleryImages.map(x => x.full.fluid.src);

  const open = index => {
    setPhotoIndex(index);
    setIsOpen(true);
  };

  return (
    <div>
      <SEO title="Gallery" pathname="/gallery" />
      <BannerHeader
        title={header.title}
        subtitle={header.subtitle}
        image={header.image}
      />

      {isOpen && (
        <Lightbox
          mainSrc={fullSize[photoIndex]}
          nextSrc={fullSize[(photoIndex + 1) % fullSize.length]}
          prevSrc={
            fullSize[(photoIndex + fullSize.length - 1) % fullSize.length]
          }
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + fullSize.length - 1) % fullSize.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % fullSize.length)
          }
        />
      )}

      <section className="section">
        <div className="container content markdown-content">
          <div className="columns is-multiline is-vcentered is-mobile">
            {galleryImages.map((x, i) => (
              <div
                className="column is-3-desktop is-4-tablet is-12-mobile"
                key={x.id}
              >
                <img
                  src={x.thumb.fluid.src}
                  onClick={() => open(i)}
                  alt={x.relativePath}
                />
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

GalleryPageTemplate.propTypes = {
  header: PropTypes.shape({
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    title: PropTypes.string,
    subtitle: PropTypes.string
  })
};

const GalleryPage = ({ data }) => {
  const { markdownRemark } = data;

  return (
    <Layout>
      <GalleryPageTemplate {...markdownRemark.frontmatter} />
      <ContactCallToAction />
      <LearnHero />
      <TestimonialCarousel />
      <ReviewBar />
    </Layout>
  );
};

GalleryPage.propTypes = {
  data: PropTypes.object.isRequired
};

export default GalleryPage;

export const aboutPageQuery = graphql`
  query GalleryPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "gallery-page" } }) {
      frontmatter {
        header {
          title
          subtitle
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        galleryImages {
          id
          relativePath
          thumb: childImageSharp {
            fluid(maxWidth: 270, maxHeight: 270) {
              ...GatsbyImageSharpFluid
            }
          }
          full: childImageSharp {
            fluid(
              maxWidth: 1024
              quality: 85
              srcSetBreakpoints: [576, 768, 992, 1200]
            ) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
