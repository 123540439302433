import React from 'react';

const BannerHeader = ({ title, subtitle, image }) => {
  var style = image
    ? {
        backgroundImage: `url(${
          !!image.childImageSharp ? image.childImageSharp.fluid.src : image
        })`,
        backgroundRepeat: 'no-repeat'
      }
    : {};
  return (
    <section className="hero banner-header  is-vcentered" style={style}>
      <div className="hero-body">
        <div className="container">
          <h1 className="title">{title}</h1>
          <h2 className="subtitle">{subtitle}</h2>
        </div>
      </div>
    </section>
  );
};

export default BannerHeader;
