import React from 'react';
import CallToActionButton from './CallToActionButton';

const ContactCallToAction = () => {
  return (
    <div className="container content">
      <div className="columns is-multiline">
        <div className="column is-12 has-text-centered">
          <h3 className="has-text-primary">
            Contact Us Today for Your Complimentary Consultation
          </h3>
        </div>
        <div className="column is-12 has-text-centered">
          <CallToActionButton to="/contact" buttonText="Contact Us" />
        </div>
      </div>
    </div>
  );
};

export default ContactCallToAction;
