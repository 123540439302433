import React from 'react';
import { Link } from 'gatsby';

const CallToActionButton = ({ buttonText, to }) => {
  return (
    <div className="columns">
      <div className="column is-offset-4 is-4">
        <div className="has-text-centered">
          <Link
            to={to}
            className="button is-primary is-uppercase is-call-to-action"
          >
            {buttonText}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CallToActionButton;
