import React from 'react';
import { Link } from 'gatsby';

const LearnHero = () => {
  return (
    <section className="hero is-primary">
      <div className="hero-body">
        <div className="container">
          <div className="columns has-text-centered is-uppercase">
            <div className="column is-four-fifths">
              <h1 className="title">
                Your move made simple with Organized Transitions
              </h1>
            </div>
            <div className="column">
              <Link
                to="/moving-company"
                className="button is-inverted is-outlined is-primary "
              >
                Learn More
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LearnHero;
